jQuery(function ($) {
    var menu = $("#menu-hauptmenue");
    var slimheader = $(".slimheader");

    $("li",menu).each(function(){
        var item = $(this);
        if(!item.children(".sub-menu").length) return;
        var pfeil = $("<span class='navarrow px-3'>&gt;</span><span class='navarrowmobil px-3'>&or;</span>");
        item.append(pfeil);
        pfeil.on("click",function(){
            $(item.parent()).children().removeClass("hover");
            item.toggleClass("hover");
            setMenuSize();
        });
    });

    //Status Nav Aktuelle Seite
    function backtodefaultnav(){
        $("li.current_page_ancestor").addClass("hover");
        $("li.current_page_item").addClass("hover");
    };
    backtodefaultnav();

    //CONTAINER GROESSE
    function setMenuSize(){
        var height = menu.height();

        $("li.hover > .sub-menu", menu).each(function(){
            $(this).css("height", "auto");
            height = Math.max(height, $(this).height());
        });

        // 30 ist das Padding des Untermenüs nach oben
        $(".hauptnavigation-container").height(height + 30);
        $(".sub-menu", menu).each(function(){
            $(this).height($(this).data("height"));
        });
        $(".sub-menu", menu).height(height);
    }



    //SLIMHEADER SHOW
    // $(window).on("scroll", function(){
    //     var scroll = $(window).scrollTop();
    //     if(scroll >= 92){
    //         slimheader.addClass("open");
    //     }else{
    //         slimheader.removeClass("open");
    //     }
    // });

    $(".navbar-toggler").on("click", function(){
        $("#hauptnavigation").toggleClass("collapse");
        if($("#hauptnavigation").hasClass("collapse")){
            slimheader.removeClass("menuopen");
        }else{
            slimheader.addClass("menuopen");
            setMenuSize();
        }
    });
});